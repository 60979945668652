exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-1-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/projs/proj1.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-1-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-2-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/projs/proj2.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-2-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-3-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/projs/proj3.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-3-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-4-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/projs/proj4.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-js-content-file-path-src-content-projs-proj-4-mdx" */)
}

